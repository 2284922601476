<template>
<div class="bills-content">
  <a-card class="mt-2">
    <div class="d-flex justify-content-between align-items-center mb-4">
      <span class="h5 mb-0"><font-awesome-icon icon="money-check"
                                               class="mt-1 mr-1 font-size-24"/> {{ $t('common.projects') }}</span>
    </div>
<!--    <div class="mt-1 mb-3">-->
<!--      <a-row :gutter="16" type="flex" align="bottom">-->
<!--        <a-col :span="4">-->
<!--          <a-statistic :title="`${$t('common.total')} ${$t('common.payouts')}`" :value="statData.count"-->
<!--                       style="margin-right: 50px">-->
<!--          </a-statistic>-->
<!--        </a-col>-->
<!--        <a-col :span="5">-->
<!--          <a-statistic :title="`${$t('common.sum')} USDT`" :value="statData.sum_usd ? statData.sum_usd : 0"-->
<!--                       prefix="" style="margin-right: 50px">-->
<!--          </a-statistic>-->
<!--        </a-col>-->
<!--      </a-row>-->
<!--    </div>-->
    <!-- Second row -->
    <a-table
            :columns="table.columns"
            :dataSource="table.rows"
            :row-key="record => record.id"
            :pagination="table.pagination"
            :loading="table.loading"
            @change="handleTableChange"
    >
      <template v-slot:id="id">
        <span>{{ id }}</span>
      </template>
      <template v-slot:name="name, record">
        {{ name }}
        <span class="d-block small text-gray-5">{{ record.slug }}</span>
        <span class="d-block mt-1 small text-gray-5">ID: {{ record.id }}</span>
      </template>
      <template v-slot:created_at="created_at">
        <span v-if="created_at">
          <span>{{ $moment(created_at).format('DD.MM.YY') }}</span>
          <span class="small d-block text-gray-5"><a-icon
            type="clock-circle"/> {{ $moment(created_at).format('HH:mm') }}</span>
        </span>
        <span v-else>-</span>
      </template>
      <template v-slot:currencies="currencies">
        <span v-if="currencies && currencies.length > 0">
          {{ currencies.map((cur) => cur.name).join(', ') }}
        </span>
        <span v-else>-</span>
      </template>
      <template v-slot:clients="clients">
        <span v-if="clients && clients > 0">
          {{ clients }}
        </span>
        <span v-else>-</span>
      </template>
      <template v-slot:agents="agents, record">
        <span v-if="agents && agents.length > 0">
          <span class="d-block">
            {{ agents.length }}
            <a v-if="table.expandedAgents.includes(record.id)" href="javascript:" @click="hideAgents(record.id)"><a-icon type="eye-invisible" /></a>
            <a v-else href="javascript:" @click="expandAgents(record.id)"><a-icon type="eye" /></a>
          </span>
          <div v-show="table.expandedAgents.includes(record.id)" class="small w-100p mt-1">
            {{ agents.map((a) => a.name).join(', ') }}
          </div>
        </span>
        <span v-else>-</span>
      </template>
      <template v-slot:settings="settings, record">
        <span v-if="settings">
          <span class="d-flex small">
            <span class="pr-1">withdrawals:</span>
            <a-switch v-model="settings.integration.withdrawal.enabled" checked-children="" un-checked-children="" size="small" @change="withdrawalToggleHandle(record.id, $event)" />
          </span>
        </span>
        <span v-else>-</span>
      </template>
      <template v-slot:status="status, record">
        <span class="project_api_key_status">
          <span v-if="record.api_key">
            <a-tooltip v-if="record.api_key.is_active" :title="`API access\nStatus: Active`" :overlayStyle="{ whiteSpace: 'pre-line' }" placement="bottom">
              <a href="javascript:" class="text-primary font-size-18"><a-icon type="api" /></a>
            </a-tooltip>
            <a-tooltip v-else :title="`API access\nStatus: Inactive`" :overlayStyle="{ whiteSpace: 'pre-line' }" placement="bottom">
              <a href="javascript:" class="text-warning font-size-18"><a-icon type="api" /></a>
            </a-tooltip>
          </span>
          <span v-else class="text-gray-5"><a-icon type="api" /></span>
        </span>
        <span class="ml-2 project_settings_icon font-size-18">
          <a-tooltip :title="`${$t('buttons.btnSettings')}`" :overlayStyle="{ whiteSpace: 'pre-line' }" placement="bottom">
            <a href="javascript:"><a-icon type="setting" /></a>
          </a-tooltip>
        </span>
      </template>
    </a-table>
  </a-card>
</div>
</template>

<script>
import { reactive } from 'vue'
import ApiService from '@/services/api/api.service'

export default {
  name: 'ProjectsList',
  data() {
    return {
      table: reactive({
        rows: [],
        columns: [
          {
            title: 'Created',
            dataIndex: 'created_at',
            key: 'created_at',
            width: '12%',
            scopedSlots: { customRender: 'created_at' },
          },
          {
            title: 'Project',
            dataIndex: 'name',
            key: 'name',
            width: '20%',
            scopedSlots: { customRender: 'name' },
          },
          {
            title: 'Currencies',
            dataIndex: 'currencies',
            key: 'currencies',
            width: '10%',
            scopedSlots: { customRender: 'currencies' },
          },
          {
            title: 'Clients',
            dataIndex: 'clients_count',
            key: 'clients',
            width: '12%',
            scopedSlots: { customRender: 'clients' },
          },
          {
            title: 'Agents',
            dataIndex: 'agents',
            key: 'agents',
            width: '15%',
            scopedSlots: { customRender: 'agents' },
          },
          {
            title: 'Settings',
            dataIndex: 'settings',
            key: 'settings',
            width: '15%',
            scopedSlots: { customRender: 'settings' },
          },
          {
            title: 'Status',
            dataIndex: 'status',
            key: 'status',
            align: 'right',
            width: '15%',
            scopedSlots: { customRender: 'status' },
          },
        ],
        loading: false,
        pagination: {
          current: 1,
          pageSize: 10,
          total: -1,
        },
        expandedAgents: [],
      }),
    }
  },
  async mounted() {
    await this.fetchProjects()
  },
  methods: {
    clipboardHandle(v) {
      // console.log(v)
      this.$clipboard(v)
      this.$notification.success({
        message: this.$t('messages.copiedToClipboard'),
        description: `${v.slice(0, 16)}...`,
      })
    },
    handleTableChange(pagination) {
      this.table.pagination.current = pagination.current
      this.fetchProjects()
    },
    async fetchProjects() {
      try {
        this.table.loading = true
        this.table.rows = await ApiService.getProjects()
        // this.table.pagination.total = response.data.meta.total
      } catch (e) {
        console.log(e)
      } finally {
        this.table.loading = false
      }
    },
    async updateTable() {
      this.table.pagination.current = 1
      await this.fetchProjects()
    },
    expandAgents(projectId) {
      this.table.expandedAgents.push(projectId)
    },
    hideAgents(projectId) {
      this.table.expandedAgents = this.table.expandedAgents.filter(i => i !== projectId)
    },
    async withdrawalToggleHandle(projectId, $event) {
      console.log(projectId)
      console.log($event)
      return ApiService.projectToggleWithdrawalEnabled(projectId).then((response) => {
        // console.log(response.data)
        const state = response.integration.withdrawal.enabled
        const statusStr = state === true ? 'ENABLED' : 'DISABLED'
        this.$message.success(`Withdrawals ${statusStr} for Project ID #${projectId}`)
      }).catch(error => {
        this.$message.error(`Error toggle settings. Message: ${error.message}`)
      })
    },
  },
}
</script>
<style>
</style>
